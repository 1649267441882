import React from 'react';
import Box from '@mui/material/Box';
import YearDropdown from './YearDropdown';
import ClassDropDown from './ClassDropDown';
import { Grid, TextField } from '@mui/material';
import TextInformation from './TextInformation';
import Button from '@mui/material/Button';

const YearInformation = ({
  fromYear,
  toYear,
  fromClass,
  toClass,
  city,
  state,
  country,
  proof,
  handleRemove,
  rec
}) => {
  const handleChange = () => {};


  return (
    <>
    <Grid container spacing={4}>
      <YearDropdown
        label="From Year"
        fromYear={fromYear}
        handleChange={handleChange}
        name={'fromYear'}
      />
      <ClassDropDown
        label="From Class"
        fromYear={fromClass}
        handleChange={handleChange}
        name={'fromClass'}
      />
      <YearDropdown
        label="To Year"
        fromYear={toYear}
        handleChange={handleChange}
        name={'toYear'}
      />
      <ClassDropDown
        label="To Class"
        fromYear={toClass}
        handleChange={handleChange}
        name={'toClass'}
      />
      <TextInformation
        label="School Name"
        value={city}
        handleChange={handleChange}
        name={'school'}
      />
      <TextInformation
        label="City"
        value={city}
        handleChange={handleChange}
        name={'city'}
      />
      <TextInformation
        label="State"
        value={state}
        handleChange={handleChange}
        name={'state'}
      />
      <TextInformation
        label="Country"
        value={country}
        handleChange={handleChange}
        name={'country'}
      />

      <div
        style={{
          width: '100%',
          textAlign: 'right',
          padding: '10px',
          marginTop: '10px',
        }}
      >
        {' '}
        <Button onClick={() => handleRemove(rec.id)}>Remove</Button>
      </div>
   </Grid>
    </>
  );
};

export default YearInformation;
