import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { MenuItem, TextField } from '@mui/material';

const YearDropdown = ({ yearValue, label, handleChange, name }) => {
  //   const handleChange = () => {};
  const now = new Date().getUTCFullYear();
  const years = Array(now - (now - 20))
    .fill('')
    .map((v, idx) => now - idx);
  return (
    <Grid item xs={3} sm={3}>
      <Typography
        variant={'subtitle2'}
        sx={{ marginBottom: 2 }}
        fontWeight={700}
      >
        {label}
      </Typography>
      <TextField
        select
        key={`fromYear-${yearValue}`}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        defaultValue={yearValue}
        label={label}
        variant="outlined"
        name={name}
        fullWidth
        onChange={handleChange}
      >
        {years.map((rec) => (
          <MenuItem key={`${label}-${rec}`} value={rec}>
            {rec.toString()}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default YearDropdown;
