import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { MenuItem, TextField } from '@mui/material';

const ClassDropDown = ({ classValue, label, handleChange, name }) => {
  const classInfo = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'];
  return (
    <Grid item xs={3} sm={3}>
      <Typography
        variant={'subtitle2'}
        sx={{ marginBottom: 2 }}
        fontWeight={700}
      >
        {label}
      </Typography>
      <TextField
        select
        key={`fromYear-${classValue}`}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        defaultValue={classValue}
        label={label}
        variant="outlined"
        name={name}
        fullWidth
        onChange={handleChange}
      >
        {classInfo.map((rec) => (
          <MenuItem key={`${label}-${rec}`} value={rec}>
            {rec.toString()}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default ClassDropDown;
