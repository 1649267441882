import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {  TextField } from '@mui/material';

const TextInformation = ({ value, label, handleChange, name }) => {
 
  return (
    <Grid item xs={3} sm={3}>
      <Typography
        variant={'subtitle2'}
        sx={{ marginBottom: 2 }}
        fontWeight={700}
      >
        {label}
      </Typography>
      <TextField
        label={label}
        variant="outlined"
        name={name}
        fullWidth
        defaultValue={value}
        onBlur={handleChange}
      />
    </Grid>
  );
};

export default TextInformation;